.xcn-dashboard-sub-nav{
    height: 100vh;
    text-align: left;    
    width: 6rem;
    transition: all 0.5s cubic-bezier(0.13, 0.88, 1, 1);
}


/* width */
.xcn-dashboard-sub-nav::-webkit-scrollbar {
    width: 8px;
    border-radius: .5rem;
  }
  
  /* Track */
  .xcn-dashboard-sub-nav::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  .xcn-dashboard-sub-nav::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  .xcn-dashboard-sub-nav::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
.xcn-dash-logo{
    height: 2rem;  
}
.xcn-nav-selected{
    border-right: 5px solid white;
}

.xcn-sidebar-links{
    padding: 0.65rem 0rem;
    border-bottom: 1px solid rgba(255,255,255,.1);
    color: #fff;
}
.xcn-sidebar-links .xcn-text-10 {
    font-weight: 500;
    font-size: 12px !important;
}
.xcn-sidebar-links:hover, .xcn-sidebar-links:active, .xcn-sidebar-links.active{
    background-color: rgba(0,0,0,.1);  
    color: #fff !important; 
}
.side-logout{
    border-color: rgba(255,255,255,.2);
}
